import React from "react";
import { slugToTitle, replaceAll } from "../../utils/helpers";
import GatsbyLink from "../GatsbyLink";
import "./Breadcrumbs.scss";

export const Breadcrumbs = ({ location }) => {
  if (!location) {
    return null;
  }

  const { pathname, origin, href } = location;
  const pathArray = pathname.split("/");
  let urlBuild = [""];
  return (
    <div className="breadcrumbs">
      <div className="inner">
        <ul>
          <li>
            <GatsbyLink to="/">Home</GatsbyLink>
          </li>
          {pathArray &&
            pathArray.map((item, index) => {
              if (item) {
                urlBuild.push(item);
                const url = `${urlBuild.join("/")}/`;
                const title = item ? item.replaceAll("-", " ") : "";
                return (
                  <li key={index}>
                    <span>|</span>
                    {item === "category" ||
                    item === "page" ||
                    item === "project-category" ? (
                      <span>{title}</span>
                    ) : (
                      <GatsbyLink to={url} decode={true}>
                        {title}
                      </GatsbyLink>
                    )}
                  </li>
                );
              }
            })}
        </ul>
      </div>
    </div>
  );
};
